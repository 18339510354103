//src/views/About.vue
<template>
  <b-container id="wrapper">
      <h2>The ATX VGC Story</h2>
      <p>We started out as a group of local VGC Players who met at Dallas Regionals and liked playing Pokemon together every week. We’d meet every night over at Mothership games and would have mini tournaments culminating in our first local Premier Challenge. When all in person events were cancelled due to COVID 19 we decided to our core group online and continue our tradition of Monday night tournaments over video chats.</p> 
      <p>After a few months we thought: “Why are we keeping this to ourselves? There were way more people at that PC then are here now- let’s see who else is interested in these fun little exhibition sets!” And the ATX VGC Monday night Friendlies were born. We’ve been hosting weekly Monday night exhibition sets every week since the beginning of May and haven’t looked back as we’ve grown our community with folks from Austin, and from across the world!</p>
      <p>It’s been our passion to create an awesome, friendly engaged community built around lifting each other up as we try to learn more, have fun, and become better competitors. If you want to get involved and participate in a Friendly exhibition come hang out in our Discord server and sign up for the weekly exhibition matches- we always love meeting new friends!</p>
  </b-container>
</template>
<style scoped>

  
</style>
<style>
</style>
<script>
export default {
  data: () => {
    return {
    };
  }
}
</script>